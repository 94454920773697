$bordersize: 5px;
$lightgrey: #A0A0A0;
$darkgrey:#808080;
.pixel-button-container {
    margin-top: 15px;
    margin-bottom: 15px;
    button {
        color: white;
        all: unset;
        border-style: solid;
        border-color: white;
        border-width: $bordersize 0;
        margin: 0 $bordersize;
        padding: 15px;
        position: relative;
        z-index: 1;
        background: $lightgrey;
        box-shadow: inset (-5px) (-5px) 0px 0px $darkgrey;
        cursor: pointer;

        &:before {
            border: inherit;
            border-width: 0 $bordersize;
            width: 100%;
            content: '';
            height: 100%;
            left: -$bordersize;
            pointer-events: none;
            position: absolute;
            top: 0;
            right: -$bordersize;
            z-index: -1;
        }
        
        &:active {
            background-color: $darkgrey;
            box-shadow: inset (-5px) (-5px) 0px 0px $lightgrey;
        }
        &:hover {
            background-color: $darkgrey;
            box-shadow: inset (-5px) (-5px) 0px 0px $lightgrey;
        }
    }
    
}

.hide-cursor {
    .typed-cursor {
        display: none;
    }
}